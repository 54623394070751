import React, { useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams // Import useParams
} from "react-router-dom";
import { Box } from "@mui/material";
import MenuBar from './MenuBar';
import DoubleLine from "./DoubleLine";
import CaveatFooter from "./CaveatFooter";
import Container from '@mui/material/Container';
import axios from 'axios';
import { useSearchParams } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe("pk_live_cBLCCVr3qpkN8hMukvjf0lEI");

export default function CheckoutPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const classId = searchParams.get("classId");

    const fetchClientSecret = useCallback(() => {
        // Create a Checkout Session
        return fetch(`/api/create-checkout-session/${classId}`, {
          method: "POST",
        })
          .then((res) => res.json())
          .then((data) => data.clientSecret);
      }, []);
    
    const options = {fetchClientSecret};

    return (
        <Container disableGutters className="container" sx={{ padding: "20px" }}>
            <MenuBar active="Classes" />
            <Box id="checkout"> {/* Apply styles */}
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={options}
                    >
                        <EmbeddedCheckout /> {/* Apply styles */}
                    </EmbeddedCheckoutProvider>
            </Box>
        </Container>
    );
}



