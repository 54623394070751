import React, { useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import MenuBar from './MenuBar';
import DoubleLine from './DoubleLine';
import CaveatFooter from './CaveatFooter';
import axios from 'axios';

export default function OrderPage() {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
  
    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');
  
      fetch(`/api/track-order/${sessionId}`)
    }, []);

    return (
        <Container disableGutters className="container" sx={{ padding: "20px" }}>
            <MenuBar active="Classes" />
            <Box sx={{ padding: "20px" }}>
                <Typography variant="h4" gutterBottom>
                    Order Successful
                </Typography>
                <Typography variant="body1">
                    Thank you for your purchase! Your order has been successfully processed.
                </Typography>
            </Box>
            <DoubleLine />
            <CaveatFooter />
        </Container>
    );
}
