import './index.css';
import './App.css';
import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import MenuBar from './MenuBar'
import Container from '@mui/material/Container';
import HeroUnit from "./HeroUnit"
import QuoteBox from "./QuoteBox"
import DoubleLine from "./DoubleLine"
import CaveatFooter from "./CaveatFooter"
import ReactGA from "react-ga4"
import ReactPixel from 'react-facebook-pixel'; 
import ShowUnit from "./ShowUnit"
import Carousel from 'react-material-ui-carousel'
import { useMediaQuery } from '@mui/material';
import theme from './caveattheme';

export default function App() {
  const [topHero, setTopHero] = useState(null)
  const [heroCards, setHeroCards] = useState([])

  useEffect(()=>{
    ReactGA.initialize("G-LN3PNYXF7B")  

    const fboptions = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init('336099940147449', fboptions);

    axios.get(`/api/data/main`).then(response => {
      let unshuffled = response.data.heroTop
      let shuffled = unshuffled
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
      setHeroCards(shuffled)
    }).catch(error => {
      console.log(error)
    })
  
    }, [])

  const fboptions = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init('336099940147449', fboptions);

  ReactPixel.pageView();

  const promoCards = heroCards.map((topHero) => 
        <HeroUnit key={topHero.Name}
        textpurple={topHero.TextPurple}
        textblack={topHero.TextBlack}
        textcaption={topHero.Caption}
        buttonlink={topHero.Link}
        buttontext={topHero.ButtonText}
        imgurl={topHero.s3image}
      />
  )

  return(
    <Container disableGutters className="container">
    <Box sx={{padding: "0 20px"}}>
    <MenuBar active="" />

      <Box className="main" >
      <Box sx={{minHeight:{xs: "450px", sm: "450px"}, marginTop: "40px"}}>
            <Carousel
                interval={80000}
                sx={{minHeight: "470px"}}
                indicators={false}
                changeOnFirstRender
            >
                {promoCards}
            </Carousel>

      </Box>


          <Box sx={{textAlign: "left"}}>
          <Box sx={{backgroundColor: "white", maxWidth: "1000px", margin: "auto", padding: "20px", marginTop: "20px"}}>
                <Box sx={{marginBottom: "10px", width: "100%"}} >
                <DoubleLine text="UPCOMING LIVE EVENTS" size="h3"/>
                </Box>
                <ShowUnit />
            </Box>
          </Box>



          <Box sx={{minHeight: "450px", marginTop: "40px", marginBottom: "20px"}}>
            <HeroUnit
            textpurple=""
            textblack="Comedy. Improv. Game Shows. Pun Championships. Science Fairs. Film Nights. Storytime."
            textcaption="Come hang with us at Caveat – or stay home and live your best introvert life with our livestreamed events."
            buttonlink="/events"
            buttontext="SEE WHAT'S ON"
            imgurl="heart.png"
                  />

        </Box>
      </Box>
      </Box>
      <Box sx={{minHeight: "200px"}}>
        <QuoteBox press />
      </Box>
      <Box padding="0 20px">
      <CaveatFooter />
      </Box>
      </Container>
  )
}
