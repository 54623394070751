import './index.css';
import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import theme from './caveattheme';




function StandardMenu(props){
    return(
    <Stack direction="row" spacing={3}  alignItems="Center" sx={{minHeight: "45px"}}>
        <Link 
            variant="h4"
            href="/events"
            color={props.active==="WhatsOn" ? theme.palette.secondary.main : "black"}
            underline="none"
        >WHAT'S ON</Link>
        <Link 
            variant="h4"
            href="/about"
            color={props.active==="AboutUs" ? theme.palette.secondary.main : "black"}
            underline="none"
        >ABOUT US</Link>
        <Link 
            variant="h4"
            href="/performers"
            color={props.active==="Performers" ? theme.palette.secondary.main : "black"}
            underline="none"
        >PERFORMERS</Link>
        <Link 
            variant="h4"
            href="/menu.pdf"
            color={props.active==="Menu" ? theme.palette.secondary.main : "black"}
            underline="none"
        >MENU</Link>
        <Link 
            variant="h4"
            href="/pitch"
            color={props.active==="Pitch" ? theme.palette.secondary.main : "black"}
            underline="none"
        >PUT ON A SHOW</Link>
        <Link 
            variant="h4"
            href="/rentals"
            color={props.active==="Rentals" ? theme.palette.secondary.main : "black"}
            underline="none"
        >RENT OUR SPACE</Link> 
        <Link 
            variant="h4"
            href="/classes"
            color={props.active==="Classes" ? theme.palette.secondary.main : "black"}
            underline="none"
        >CLASSES</Link>
        <Link 
            variant="h4"
            href="/contact"
            color={props.active==="Contact" ? theme.palette.secondary.main : "black"}
            underline="none"
        >CONTACT</Link>
    </Stack>
    )
}

function MobileMenu(props){

    const [menuState, setMenuState] = useState(false)

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setMenuState(open);
      };

    return(
        <Box>
        <Button onClick={() => setMenuState(true)}><MenuIcon /></Button>
        <Drawer
            anchor="top"
            open={menuState}
            onOpen={toggleDrawer(true)}
            onClose={toggleDrawer(false)}
            PaperProps= {{sx: {height: "100%"}}}
        >
            <Box sx={{width: "100%"}}>
            <Button onClick={toggleDrawer(false)} sx={{width: "10px", position: "relative", float: "right"}}><CloseIcon /></Button>
            </Box>
        <Stack spacing={2} sx={{alignItems: "center",justifyContent: "center", height: "100%"}}>
        <Link 
            variant="h4"
            href="/events"
            color={props.active==="WhatsOn" ? theme.palette.secondary.main : "black"}
            underline="none"
        >WHAT'S ON</Link>
        <Link 
            variant="h4"
            href="/about"
            color={props.active==="AboutUs" ? theme.palette.secondary.main : "black"}
            underline="none"
        >ABOUT US</Link>
        <Link 
            variant="h4"
            href="/performers"
            color={props.active==="Performers" ? theme.palette.secondary.main : "black"}
            underline="none"
        >PERFORMERS</Link>
        <Link 
            variant="h4"
            href="/menu.pdf"
            color={props.active==="Menu" ? theme.palette.secondary.main : "black"}
            underline="none"
        >MENU</Link>
        <Link 
            variant="h4"
            href="/pitch"
            color={props.active==="Pitch" ? theme.palette.secondary.main : "black"}
            underline="none"
        >PUT ON A SHOW</Link>
        <Link 
            variant="h4"
            href="/rentals"
            color={props.active==="Rentals" ? theme.palette.secondary.main : "black"}
            underline="none"
        >RENT OUR SPACE</Link> 
        <Link 
            variant="h4"
            href="/shop"
            color={props.active==="Shop" ? theme.palette.secondary.main : "black"}
            underline="none"
        >SHOP</Link>
        <Link 
            variant="h4"
            href="/contact"
            color={props.active==="Contact" ? theme.palette.secondary.main : "black"}
            underline="none"
        >CONTACT</Link>
        <SearchIcon /> 
        </Stack>
        </Drawer>
        </Box>
    )
}


export default function MenuBar(props){
    
    const theme = useTheme();



    return(
        <Box>
            <Stack direction="row" className="top-menu">
                <Link href="/"><img wdith="40%" src="/logo.svg" /></Link>
                {useMediaQuery(theme.breakpoints.up('sm')) ? <StandardMenu active={props.active}/> : <MobileMenu active={props.active} />}

            </Stack>
        </Box>   
    )
}