import './index.css';
import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuBar from './MenuBar';
import DoubleLine from "./DoubleLine";
import CaveatFooter from "./CaveatFooter";
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export default function Classes() {
  const [classInfo, setClassInfo] = useState([]);
  const [ourClasses, setOurClasses] = useState([]);

  useEffect(() => {
    console.log("Fetching classes data...");
    axios.get(`/api/data/classes`).then(response => {
      setClassInfo(response.data.data);
      console.log(response.data.data);
    }).catch(error => {
      console.log(error);
    });

    console.log("Fetching our classes data...");
    axios.get(`/api/data/our-classes`).then(response => {
      setOurClasses(response.data.data);
      console.log(response.data.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  if (!classInfo.length || !ourClasses.length) {
    return <Typography variant="body1">Loading...</Typography>;
  }

  const bannerImage = classInfo.find(item => item.location === 'banner');
  const description = classInfo.find(item => item.location === 'main-description');

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return `${date.toLocaleDateString('en-US', { weekday: 'long' })}s starting ${date.toLocaleDateString('en-US', options)}`;
  };


  return (
    <Container disableGutters className="container" sx={{ padding: "20px" }}>
      <MenuBar active="Classes" />
      <Box sx={{ maxWidth: "1000px", margin: "auto",marginTop: "30px"}}>
        {bannerImage && (
          <img src={bannerImage.url} alt="Classes Banner" style={{ width: '100%' }} />
        )}
      </Box>
      <DoubleLine text="Classes at Caveat" color="black" size="h3" />
      {description && (
        <Box sx={{ color: "black", marginTop: "10px", maxWidth: "960px", margin: "auto" }} dangerouslySetInnerHTML={{ __html: description.text }} />
      )}
      <Box sx={{ margin: "40px auto", maxWidth: "960px", padding: "20px", backgroundColor: "#282828" }}>
        <DoubleLine text="OUR CLASSES" color="white" size="h3" />
        <Box sx={{ marginTop: "20px" }}>
          {ourClasses.map((classItem, index) => (
            <Card key={index} sx={{ 
              marginBottom: "20px", 
              backgroundColor: "white", 
              color: "black", 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              flexDirection: { xs: 'column', md: 'row' } 
            }}>
              {classItem.Instructor && classItem.Instructor.Photo && (
                <Box sx={{ 
                  position: 'relative', 
                  width: { xs: '100%', md: '200px' }, 
                  height: { xs: 'auto', md: '200px' }, 
                  marginRight: { xs: '0', md: '20px' }, 
                  marginBottom: { xs: '20px', md: '0' }, 
                  border: 'none', 
                  flexShrink: 0 
                }}>
                  <CardMedia
                    component="img"
                    image={classItem.Instructor.Photo[0].url}
                    alt={classItem.Instructor.Name}
                    sx={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center" }}
                  />
                  <Box sx={{ position: 'absolute', bottom: '0', right: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', padding: '5px', borderRadius: '0 0 0 10px' }}>
                    <Typography variant="body2">{classItem.Instructor.Name}</Typography>
                  </Box>
                </Box>
              )}
              <CardContent sx={{ flex: '1', textAlign: { xs: 'center', md: 'left' } }}>
                <Typography variant="h3" sx={{ marginBottom: "10px" }}>{classItem.Name}</Typography>
                <Typography variant="body2">{formatDate(classItem['Start Date'])}</Typography>
                <Typography variant="body2">{classItem.Time}</Typography>
                <Typography variant="body2">{classItem.Location}</Typography>
                <Typography variant="body2">{classItem['Number of Classes']} classes</Typography>
                <Typography variant="body2">${classItem.Price}</Typography>
              </CardContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: { xs: '0', md: "20px" }, marginTop: { xs: '20px', md: '0' } }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  sx={{ marginBottom: "10px" }} 
                  component={Link} 
                  to={`/register?classId=${classItem.id}`}
                >
                  Register
                </Button>
                {classItem['Class Show'] && (
                  <Box sx={{ backgroundColor: '#f0f0f0', padding: '5px 10px', borderRadius: '5px', textAlign: 'center', maxWidth: '150px', whiteSpace: 'normal' }}>
                    <Typography variant="body2">Includes a class show and video of your performance</Typography>
                  </Box>
                )}
              </Box>
            </Card>
          ))}
        </Box>
      </Box>
      <CaveatFooter />
    </Container>
  );
}
