import './index.css';  
import './App.css';
import './Calendar.css'; 
import './CalendarView.css'
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useSearchParams } from "react-router-dom";
import { Checkbox, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MenuBar from './MenuBar'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import HeroUnit from "./HeroUnit"
import ShowCard from "./ShowCard";
import Calendar from "react-calendar"
import DoubleLine from "./DoubleLine"
import CaveatFooter from "./CaveatFooter"
import CalendarView, { CalendarDayHeader } from "./CalendarView"
import ReactGA from "react-ga"
import ReactPixel from 'react-facebook-pixel';
import Link from '@mui/material/Link';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
    

export default function ShowUnit () {

    ReactGA.initialize([{trackingId: "G-LN3PNYXF7B", gaOptions: {debug_mode: true}}])  
    ReactGA.send({ hitType: "pageview" });


    const fboptions = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('336099940147449', fboptions);
    ReactPixel.pageView();

    const [searchParams, setSearchParams] = useSearchParams();
    const tag = searchParams.get('tag') ? searchParams.get('tag').split(",") : ""
    const livestream = searchParams.get('livestream')

    const theme = useTheme();

    const [shows, setShows] = useState(null)
    const [tags, setTags] = useState(null)

    const [content, setContent] = useState([])
    const [style, setStyle] = useState([])
    const [vibe, setVibe] = useState([])

    const [chips, setChips] = useState([])

    const [calendarOpen, setCalendarOpen] = useState(false)
    const [dateRange, setDateRange] = useState([new Date(), new Date()])
    const [resetDate, setResetDate] = useState(true)

    var currentTime = new Date()

    const [yearAndMonth, setYearAndMonth] = useState([currentTime.getFullYear(), currentTime.getMonth()+1]);

    useEffect(() => {
        document.title = 'Caveat Events';
      }, []);

    useEffect(()=>{
        axios.get(`/api/events/upcoming`).then(response => {
          setShows(response.data)
        }).catch(error => {
          console.log(error)
        })
    
      }, [])

    useEffect(()=>{
    axios.get(`/api/tags`).then(response => {
        setTags(response.data)
    }).catch(error => {
        console.log(error)
    })
    console.log(livestream)
    if(livestream){
        setLivestreamOnly(true)
    }

    }, [])

    useEffect(() => {
        const newchips = content.concat(style).concat(vibe)
        setChips(newchips)
    }, [content,style,vibe])

    useEffect(() => {
        if(tags){
            if(tag){
                console.log(tag)
                tag.forEach( tagitem => {
                    console.log(tagitem)
                    const t = tags['records'].find(e => e['fields']['Name'] === tagitem)
                    if(t){
                        if(t['fields']['Menu'] === 'Content'){
                            setContent([tagitem])
                        }
                        if(t['fields']['Menu'] === 'Style'){
                            setStyle([tagitem])
                        }
                        if(t['fields']['Menu'] === 'Vibe'){
                            setVibe([tagitem])
                        }
                    }
                })   
            }
        }
    }, [tags])


    const handleContentChange = (event) => {
        setContent(event.target.value)
    }

    const handleStyleChange = (event) => {
        setStyle(event.target.value)
    }

    const handleVibeChange = (event) => {
        setVibe(event.target.value)
    }

    const handleResetButton = () => {
        setContent([])
        setStyle([])
        setVibe([])
        setResetDate(true)
        setDisplayState("default")
        setDateRange([new Date(), new Date()])
        setLivestreamOnly(false)
    }

    const handleDelete = (chipToDelete) => () => {
        setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
        if(content.includes(chipToDelete)) { 
            setContent((key) => content.filter((key) => key !== chipToDelete))
        }
        if(style.includes(chipToDelete)) { 
            setStyle((key) => style.filter((key) => key !== chipToDelete))
        }
        if(vibe.includes(chipToDelete)) { 
            setVibe((key) => vibe.filter((key) => key !== chipToDelete))
        }
        if(chipToDelete == "datechip"){
            setResetDate(true)
            setDateRange([new Date(), new Date()])
        }
      };

    function ChipList(){
        var chiplist = chips.map((chip) =>
            <Chip key={chip} label={chip} color="primary" size="small" onDelete={handleDelete(chip)} sx={{marginRight: "10px"}}/>
        )
        var datelist = ""
        if(!resetDate){
            datelist = <Chip 
            key={"datechip"} color="primary" size="small" onDelete={handleDelete("datechip")} sx={{marginRight: "10px"}}
            label={"Date: "+(dateRange[0].getMonth()+1)+"/"+dateRange[0].getDate()+" - "+(dateRange[1].getMonth()+1)+"/"+dateRange[1].getDate()}
            />
        }
        return (
            <Box>
            {chiplist}
            {datelist}
            </Box>
            )
    }

    const handleCalendarOpen = () => {
        setCalendarOpen(true)
    }

    const handleCalendarClose = () => {
        setCalendarOpen(false)
    }

    function CalendarDialogue(props){
        const { onClose, selectedValue, open} = props;

        const handleClose = () => {
            onClose(selectedValue)
        }

        const handleOnChange = (range) => {
            setDateRange(range)
            setResetDate(false)
            onClose(selectedValue)
        }

        return(
            <Dialog onClose={handleClose} open={open}> 
                <Box>
                    <Calendar selectRange  onChange={handleOnChange} value={dateRange}/>
                </Box>
            </Dialog>
        )
    }

    const [eventDisplay, setEventDisplay] = useState("grid");
    const handleChangeDisplay = (event, newDisplay) => {
        if(newDisplay!=null){
        setEventDisplay(newDisplay)
        }
    }
    const [livestreamOnly, setLivestreamOnly] = useState(false);
    const isWide = useMediaQuery(theme.breakpoints.up('md'));

    const handleLivestreamOnly = (event) => {
        if(event){
            setLivestreamOnly(event.target.checked);
        }
      };

    useEffect(() => {
        var tagarray = content.concat(style).concat(vibe)
        var tagquery = tagarray.join(",")
        console.log(tagquery)
        var query = ""
        if(livestreamOnly){
            query = "?livestream=true"
            if(tagquery){
                query += "&tag="+tagquery
            }
        }
        else{
            if(tagquery){
                query = "?tag="+tagquery
            }
        }
        console.log(query)
        window.history.pushState("object or string", "Title", window.location.pathname+query);
    } ,[tag,livestreamOnly,content,style,vibe])

    function ShowSelector(){
        
        if(tags!=null){
            let tagsarray = tags.records.filter(res=>res.fields.Website)
            const itemsContent = tagsarray.filter(res=>res.fields.Menu==="Content").map(ele=>ele.fields.Name)
            const menuContent = itemsContent.map((item) =>
                <MenuItem key={item} value={item}>{item}</MenuItem>  
            )

            const itemsStyle = tagsarray.filter(res=>res.fields.Menu==="Style").map(ele=>ele.fields.Name)
            const menuStyle = itemsStyle.map((item) =>
                <MenuItem key={item} value={item}>{item}</MenuItem>  
            )

            const itemsVibe = tagsarray.filter(res=>res.fields.Menu==="Vibe").map(ele=>ele.fields.Name)
            const menuVibe = itemsVibe.map((item) =>
                <MenuItem key={item} value={item}>{item}</MenuItem>  
            )

            var toggleMenu = ""
            if(isWide){
                toggleMenu = 
                <Grid item xs={12} sm={2}>
                <Box sx={{width: "100%", height: "54px", border: "solid", borderColor: "#cdcdcd", borderWidth: "1px 0px 1px 0px", background: {xs: 'none', sm:'linear-gradient(0deg, #ffffff 0%, #ededed 90%)'}}}>
                    <ToggleButtonGroup 
                        value={eventDisplay}
                        exclusive
                        onChange={handleChangeDisplay}
                        color="primary"
                        size="small"
                        fullWidth
                        sx={{height: "55px"}}
                    >
                        <ToggleButton value="grid" sx={{border: "none", borderRadius: "0"}}><Typography variant="label">GRID</Typography></ToggleButton>
                        <ToggleButton value="calendar" sx={{border: "none", borderRadius: "0"}}><Typography variant="label">CALENDAR</Typography></ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                </Grid>
                }

            const menus = 
            <Box sx={{width: "100%"}}>
            <Grid container spacing={0}>
                {toggleMenu}
                <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                <Select
                    labelId="shows-select-content-label"
                    id="shows-select-content"
                    value={content}
                    renderValue={() => {return("CONTENT")}}
                    onChange={handleContentChange}
                    variant='outlined'
                    multiple
                    displayEmpty
                >
                    {menuContent}
                </Select>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                <Select
                    labelId="shows-select-style-label"
                    id="shows-select-style"
                    value={style}
                    onChange={handleStyleChange}
                    renderValue={() => {return("STYLE")}}
                    variant='outlined'
                    multiple
                    displayEmpty
                >
                    {menuStyle}
                </Select>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                <Select
                    labelId="shows-select-vibe-label"
                    id="shows-select-vibe"
                    value={vibe}
                    onChange={handleVibeChange}
                    renderValue={() => {return("VIBE")}}
                    variant='outlined'
                    multiple
                    displayEmpty
                >
                    {menuVibe}
                </Select>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                <Box sx={{display: "flex", width: "100%", height: "54px", border: "solid", borderColor: "#cdcdcd", borderWidth: "1px 0px 1px 1px", background: {xs: 'none', sm:'linear-gradient(0deg, #ffffff 0%, #ededed 90%)'}}}>
                   <Button variant='contained' onClick={handleCalendarOpen} sx={{position: "flex",margin: "auto", marginTop: "15px", width: "80%"}}>
                        <CalendarMonthIcon /><Typography variant="h5">DATE RANGE</Typography>
                    </Button>
                    <CalendarDialogue 
                        selectedValue={dateRange} 
                        open={calendarOpen}
                        onClose={handleCalendarClose}
                    />
                </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                <Box sx={{display: "flex", width: "100%",  height: "54px", border: "solid", borderColor: "#cdcdcd", borderWidth: "1px 0px 1px 0px", background: {xs: 'none', sm:'linear-gradient(0deg, #ffffff 0%, #ededed 90%)'}}}>
                <Button variant="contained" onClick={handleResetButton} sx={{position: "flex",margin: "auto", marginTop: "15px", width: "80%"}}><Typography variant="h5">Reset</Typography></Button>
                </Box>
                </Grid>
            </Grid>
            <Grid container sx={{marginTop: "10px"}} alignItems="center"> 
            <FormControlLabel control=
                       {<Checkbox checked={livestreamOnly} onChange={handleLivestreamOnly} />} label="Livestream" />
                <ChipList />
            </Grid>
            </Box>

            return(menus)
        }
        else{ return("Loading...")}
    }
    
    const [filteredShows, setFilteredShows] = useState([]);

    useEffect(() => {
        if (shows) {
            console.log(livestreamOnly);
            const now = new Date();
            const ninetyMinutesAgo = new Date(now.getTime() - 90 * 60000);
    
            if (content == "" && style == "" && vibe == "" && resetDate && !livestreamOnly) {
                setFilteredShows(shows.records.filter(s => new Date(s.fields['Event start date and time']) >= ninetyMinutesAgo));
            } else {
                let dateFilteredShows = shows.records.filter(s => {
                    const date = new Date(s.fields['Event start date and time']);
                    return date >= ninetyMinutesAgo && (resetDate || (date >= dateRange[0] && date <= dateRange[1]));
                });
    
                if (livestreamOnly) {
                    dateFilteredShows = dateFilteredShows.filter(res => res.fields['Livestream']);
                }
                console.log(dateFilteredShows);
                setFilteredShows(dateFilteredShows.filter(res => res.fields['Tags'].includes(content)).filter(res => res.fields['Tags'].includes(style)).filter(res => res.fields['Tags'].includes(vibe)));
            }
        }
    }, [shows, content, vibe, style, dateRange, livestreamOnly]);

    const [displayState, setDisplayState] = useState("default");

    function SelectedEvents(){

        var defaultShows = 4

        if(displayState === "default"){
                defaultShows = 4
            }
        if(displayState === "expanded"){
                defaultShows = 20
            }
        if(displayState === "all"){
                defaultShows = 200
        }

        if(content=="" && style=="" && vibe=="" && resetDate){ 
            var selectedShows = filteredShows.slice(0,defaultShows)
        } else {
            var selectedShows = filteredShows
        }

        if(shows){

            var showList = selectedShows.map((show) =>
                <Grid key={show.id} justifyContent="center" item container xs={12} sm={6} md={4} lg={3}><ShowCard show={show}></ShowCard></Grid>
            )

            return(
                <Box sx={{marginTop: "20px"}}>
                <Grid container justifyContent="center" spacing={2}>
                    {showList}
                    <Grid item xs={12}>
                        <Box sx={{width: "100%", margin: "auto"}}>
                            <Button sx={{margin: "auto", display: displayState==="all"? "none" : "block"}} onClick={() => {displayState === "default" ? setDisplayState("expanded"): setDisplayState("all")}}>{displayState == "expanded" ? "SHOW ALL" : "SHOW MORE"}</Button>
                        </Box>
                    </Grid>
                </Grid>
                </Box>
                )
        } else { return("Loading...") }
    }

    function CalendarEvents(){

        return(
            <Box>
            <CalendarView
                yearAndMonth={yearAndMonth}
                onYearAndMonthChange={setYearAndMonth}
                renderDay={(calendarDayObject) => (
                <div>
                    <CalendarDayHeader calendarDayObject={calendarDayObject} />
                    {getShowDates(calendarDayObject)}
                </div>
                )}
            />
        </Box>
        )

    }

    function getShowDates(calendarDayObject){

        if(shows)
        var dateFilteredShows = filteredShows.filter( s => {
            return( s.fields.datestring === calendarDayObject.dateString )
        })
        else{
            return("")
        }

        var showList = dateFilteredShows.map((s) => 
                <Box sx={{margin: "5px 5px 0px 5px"}}>
                    <Box>
                        <Typography variant="calendarTime">{s.fields['Event start TIME ONLY']}</Typography>
                    </Box>
                    <Link variant="calendarTitle" underline="none" href={"/events/"+s.fields.slug}>
                        <Typography variant="calendarTitle" sx={{'&:hover': {color: "#662573"}}}>
                            {
                                s.fields.Event.toUpperCase()
                            }
                        </Typography>                 
                        </Link>
                </Box>
        )

        return(
            <Box sx={{minHeight: "30px"}}>
            {showList}
            </Box>
            )
    }

    return(
        <Box sx={{minHeight: "632px"}}>
        <ShowSelector />
        { eventDisplay == "grid" ?
        <SelectedEvents /> :
        <CalendarEvents />
        }
        </Box>
    )

}